import { makeStyles } from '@admitkard/ccl/style';
import { useRive } from '@rive-app/react-canvas';
import React from 'react';
import grillSVG from './grillSVG';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { SuaveButton } from '@admitkard/ccl';

const ExpertModal = dynamic(() => import('../FeaturesSection/ExpertModal'));

// eslint-disable-next-line max-len
const menImageUrl = 'https://assets.admitkard.com/images/homepage_mentor_chat_secondary-min.webp';
// eslint-disable-next-line max-len
const womenImageUrl = 'https://assets.admitkard.com/images/homepage_mentor_chat_primary-min.webp';

const flagUrlIndia = 'https://assets.admitkard.com/images/Flag_of_India.webp';

const flagUrlUSA =
  // eslint-disable-next-line max-len
  'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/100px-Flag_of_the_United_States.svg.png?20151118161041';

const useStyles = makeStyles(
  (theme) => ({
    outerContainer: {
      boxSizing: 'border-box',
      width: '100%',
    },
    rootContainer: {
      backgroundColor: theme.colorPalette.lavender.main,
      margin: '0 1rem',
      fontFamily: 'Poppins, sans-serif !important',
      borderRadius: '1rem',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '1.5rem',
      '@media screen and (min-width: 992px)': {
        margin: '0',
        flexDirection: 'row-reverse',
        backgroundColor: 'transparent',
        justifyContent: 'space-between',
        position: 'relative',
        overflow: 'visible',
      },
    },
    grillBGContainer: {
      top: '-50%',
      left: '-34%',
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: -1,
      display: 'none',
      opacity: 0.5,
      '& svg': {
        width: '61rem',
        height: '64.5rem',
      },
      '@media screen and (min-width: 992px)': {
        display: 'block',
      },
    },
    headerSectionContainer: {
      margin: '0 1rem',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.5rem',
      '@media screen and (min-width: 992px)': {
        margin: '0',
        paddingTop: '0',
      },
    },
    title1: {
      width: '17rem',
      color: theme.colorPalette.indigo.main,
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '140%',
      marginBottom: '1rem',
      '@media screen and (min-width: 992px)': {
        fontSize: '2rem',
        width: '29.25rem',
        marginBottom: '2rem',
        backgroundColor: '#FFFFFF',
      },
    },
    title1Highlight: {
      color: 'hsla(266, 100%, 67%, 1)',
      display: 'inline',
    },
    subtitle: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.3125rem',
      color: theme.colorPalette.indigo.main,
      marginBottom: '1.25rem',
      '@media screen and (min-width: 992px)': {
        lineHeight: '1.75rem',
        fontSize: '1.125rem',
        width: '29.25rem',
        marginBottom: '4rem',
        backgroundColor: '#FFFFFF',
      },
    },
    graphicsContainer: {
      display: 'flex',
      columnGap: '0.375rem',
      margin: '0 0.5rem 0.5rem',
      '@media screen and (min-width: 992px)': {
        height: '31.0625rem',
      },
    },
    graphicsSubContainer: {
      width: '100%',
      flex: '1',
      borderRadius: '0.625rem',
      overflow: 'hidden',
      position: 'relative',
      aspectRatio: '43/66',
      '@media screen and (min-width: 992px)': {
        borderRadius: '1.25rem',
        aspectRatio: 'unset',
        width: '20.3556rem',
        flex: 'unset',
      },
    },
    graphicsChatContainer: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '0.625rem',
      flex: '1',
      aspectRatio: '43/66',
      '@media screen and (min-width: 992px)': {
        aspectRatio: 'unset',
        height: '22.125rem',
        width: '14.21rem',
        borderRadius: '1rem',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flex: 'unset',
        backgroundColor: 'white',
      },
    },
    chatSubImageContainer: {
      width: '4.25rem',
      height: '5.125rem',
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
      borderRadius: '0.625rem',
      overflow: 'hidden',
      '& span': {
        zIndex: 1,
      },
      '@media screen and (min-width: 992px)': {
        width: '8rem',
        height: '9.5rem',
        top: '1rem',
        right: '1rem',
      },
    },
    chatRiveAnimationMobile: {
      width: '100%',
      height: '100%',
      marginTop: '0.41rem',
      display: 'none',
      '@media screen and (max-width: 992px)': {
        display: 'block',
      },
    },
    chatRiveAnimationDesktop: {
      width: '100%',
      height: '100%',
      marginTop: '0.41rem',
      display: 'none',
      '@media screen and (min-width: 992px)': {
        display: 'block',
      },
    },
    countryFlagCircle1: {
      height: '1.25rem',
      width: '1.25rem',
      bottom: '0.75rem',
      left: '0.75rem',
      borderRadius: '1rem',
      position: 'absolute',
      zIndex: 1,
      border: '1.5px solid white',
      backgroundPositionX: '50%',
      backgroundSize: 'cover',
      backgroundImage: `url(${flagUrlIndia})`,
      '@media screen and (min-width: 992px)': {
        height: '2.375rem',
        width: '2.375rem',
        bottom: '0.75rem',
        left: '0.75rem',
        borderRadius: '2rem',
        borderWidth: '3px',
      },
    },
    countryFlagCircle2: {
      height: '0.75rem',
      width: '0.75rem',
      bottom: '0.25rem',
      left: '0.25rem',
      borderRadius: '1rem',
      position: 'absolute',
      zIndex: 1,
      border: '1.5px solid white',
      backgroundPositionX: '25%',
      backgroundSize: 'cover',
      backgroundImage: `url(${flagUrlUSA})`,
      '@media screen and (min-width: 992px)': {
        height: '1rem',
        width: '1rem',
        bottom: '0.5rem',
        left: '0.5rem',
        borderRadius: '2rem',
        borderWidth: '2px',
      },
    },
    cardButtonContainer: {
      zIndex: 1,
      height: '3rem',
      width: 'max-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '1rem',
      gap: '0.625rem',
      background: theme.colorPalette.indigo.main,
      borderRadius: '1.875rem',
      boxSizing: 'border-box',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontWeight: 400,
      color: '#F7EFFF',
      border: 'none',
      textDecoration: 'none',
      '&:hover': {
        transform: 'translateY(-0.2rem)',
        boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
      },
      '@media screen and (min-width: 992px)': {
        fontSize: '1.1rem',
        height: '3.5rem',
      },
    },
    cardButtonIconContainer: {
      height: '1.25rem',
      aspectRatio: '1',
      '@media screen and (min-width: 992px)': {
        height: '1.5rem',
      },
      '& svg': {
        height: 'inherit',
        width: 'inherit',
      },
    },
  }),
  { name: 'MentorChatSection' }
);

const MentorChatSection: React.FunctionComponent = () => {
  const classes = useStyles();
  const [isExpertModal, setIsExpertModal] = React.useState(false);

  const { RiveComponent: MentorChatMobile } = useRive({
    src: 'https://assets.admitkard.com/rive/homepage_mobile_mentor_connect_chat.riv',
    autoplay: true,
  });

  const { RiveComponent: MentorChatDesktop } = useRive({
    src: 'https://assets.admitkard.com/rive/homepage_desktop_mentor_connect_chat.riv',
    autoplay: true,
  });
  const handleOnClick = () => {
    if (setIsExpertModal) {
      setIsExpertModal(true);
    }
  };
  return (
    <div className={classes.outerContainer}>
      <div className={classes.rootContainer}>
        <div className={classes.grillBGContainer}>{grillSVG}</div>
        <div  id='mentor-chat-section' className={classes.headerSectionContainer}>
          <span className={classes.title1}>
            get
            <span className={classes.title1Highlight}> real guidance </span>
            from
            <span className={classes.title1Highlight}> real students </span>
            through mentor connect
          </span>
          <span className={classes.subtitle}>
            Talk to students who already have reached your dream university pursuing your dream course
          </span>
          <div style={{ width: 'fit-content' }}>
            <SuaveButton id="cta-mentor-chat" onClick={handleOnClick} variants="primary"
              size='medium'
            >
              Talk to expert
            </SuaveButton>
          </div>
        </div>
        <div id='mentor-chat-section-graphics' className={classes.graphicsContainer}>
          <div className={classes.graphicsSubContainer}>
            <div className={classes.countryFlagCircle1} />
            <div className={classes.chatSubImageContainer}>
              <Image width={128} height={152} src={menImageUrl} />
              {/* <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={menImageUrl} /> */}
              <div className={classes.countryFlagCircle2} />
            </div>
            {/* <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={womenImageUrl} /> */}
            <Image width={326} height={520} src={womenImageUrl} />
          </div>
          <div className={classes.graphicsChatContainer}>
            <MentorChatMobile className={classes.chatRiveAnimationMobile} />
            <MentorChatDesktop className={classes.chatRiveAnimationDesktop} />
          </div>
        </div>
      </div>
      <ExpertModal isModal={isExpertModal} setIsModal={setIsExpertModal} />
    </div>
  );
};

export default MentorChatSection;
